import React from 'react'
import Navbar from './Navbar'
import MainContainer from './MainContainer'
import Footer from './Footer'
import WIP from './WorkInProgress'
import Sidebar from './Sidebar'
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom'
import Spent from './Spent'
import Savings from './Savings'
import Shopping from './Shopping'
import ScanIt from './ScanIt'

export default function Main() {
    let isWorking = true
    return (
        <>
            {isWorking &&
                <WIP></WIP>
            }
            {!isWorking &&
                <div>
                    <div className=''>
                        <Navbar></Navbar>
                    </div>
                    <div className='flex h-full'>
                        <div className='flex-none'>
                            <Sidebar></Sidebar>
                        </div>

                        {/* <MainContainer></MainContainer> */}
                        {/* <Footer></Footer> */}
                    </div>
                </div>
            }

        </>
    );

}