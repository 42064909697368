import React from 'react'

export default function Main() {

    return (
        <>
            <div className='flex relative top-[15rem] justify-center'>
                <img alt="WIP" className='h-[20rem] w-[20rem]' src='images/wokrinprogress.png'></img>
            </div>        
        </>
    );

}